import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './cards.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Timer from '../../Product/common/timer'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { handleRedirectInternal, sellerNameFormat } from '../../Product/common/components'
import moment from 'moment-timezone'
import { Button } from '@material-ui/core'
import { getImageurl } from '../../utils/commonFunctions'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const LotsGridAuction = ({
    data,
    details,
    isFrame,
    timerTheme,
    // lotCount,
    auctionData,
    theme,
    noActionBtn,
    noviewbutton,
    cardTheme,
    openCategory,
}) => {
    const addDefaultSrc = (e) => {
        e.target.src = `${global?.storeDetails?.logoValue}`
    }
    const history = useHistory()
    const [time, setTime] = useState(new Date())
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setTime(new Date())
    //     }, 1000)
    //     return () => clearInterval(interval)
    // }, [])
    return (
        <>
            {data ? (
                <SkeletonTheme key={data.id} color="#d6d6d6" highlightColor="#e8e8e8">
                    <div className={`card grid-card ${data.auction ? 'auciton' : 'buynow'}`}>
                        {data.file_name ? (
                            <div className="grid-img-wrap grid-img-wrap_single">
                                <div
                                    onClick={(e) =>
                                        handleRedirectInternal(
                                            history,
                                            parseInt(data?.event_enable ? data.event_enable : 0) ==
                                                1
                                                ? `eventAuction/${data.id}`
                                                : details,
                                        )
                                    }
                                    style={{ cursor: 'pointer' }}
                                >
                                    <LazyLoadImage
                                        src={getImageurl(data.store_id.toString(), data.file_name)}
                                        effect="blur"
                                        placeholderSrc="/assets/svg/imageLoading.svg"
                                        height="100%"
                                        width="100%"
                                        className="gridImage"
                                        onError={addDefaultSrc}
                                        alt={data.title}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="grid-img-wrap">
                                <Link
                                    to={details}
                                    className="qurantHref"
                                    target={isFrame ? '_blank' : ''}
                                >
                                    <LazyLoadImage
                                        src={getImageurl(
                                            data.store_id.toString(),
                                            data?.lot_images.length > 0
                                                ? data.lot_images[0].file_name
                                                : '',
                                        )}
                                        effect="blur"
                                        placeholderSrc="/assets/svg/imageLoading.svg"
                                        height="100%"
                                        width="100%"
                                        className="gridImage"
                                        onError={addDefaultSrc}
                                        alt={data.title}
                                    />
                                </Link>
                                <Link
                                    to={details}
                                    className="qurantHref"
                                    target={isFrame ? '_blank' : ''}
                                >
                                    <LazyLoadImage
                                        src={getImageurl(
                                            data.store_id.toString(),
                                            data.lot_images.length > 1
                                                ? data.lot_images[1].file_name
                                                : '',
                                        )}
                                        effect="blur"
                                        placeholderSrc="/assets/svg/imageLoading.svg"
                                        height="100%"
                                        width="100%"
                                        className="gridImage"
                                        onError={addDefaultSrc}
                                        alt={data.title}
                                    />
                                </Link>
                                <Link
                                    to={details}
                                    className="qurantHref"
                                    target={isFrame ? '_blank' : ''}
                                >
                                    <LazyLoadImage
                                        src={getImageurl(
                                            data.store_id.toString(),

                                            data.lot_images.length > 2
                                                ? data.lot_images[2].file_name
                                                : '',
                                        )}
                                        effect="blur"
                                        placeholderSrc="/assets/svg/imageLoading.svg"
                                        height="100%"
                                        width="100%"
                                        className="gridImage"
                                        onError={addDefaultSrc}
                                        alt={data.title}
                                    />
                                </Link>
                                <Link
                                    to={details}
                                    className="qurantHref"
                                    target={isFrame ? '_blank' : ''}
                                >
                                    <LazyLoadImage
                                        src={getImageurl(
                                            data.store_id.toString(),
                                            data.lot_images.length > 3
                                                ? data.lot_images[3].file_name
                                                : '',
                                        )}
                                        effect="blur"
                                        placeholderSrc="/assets/svg/imageLoading.svg"
                                        height="100%"
                                        width="100%"
                                        className="gridImage"
                                        onError={addDefaultSrc}
                                        alt={data.title}
                                    />
                                </Link>
                            </div>
                        )}
                        <div className="card-body">
                            <div
                                className="list-title"
                                dangerouslySetInnerHTML={{
                                    __html: data.title,
                                }}
                                onClick={() => handleRedirectInternal(history, details)}
                            />

                            <div className="lots-details">
                                <div className="">
                                    {data.lotCount} item{data.lotCount > 1 && 's'}
                                </div>

                                <div className="pick-up">
                                    <img src="/lots-images/shipping-truck.svg" alt="" />
                                    Pick up only
                                </div>
                            </div>

                            <div className="timer-wrapper li-timer-wrap">
                                {data.con_check ? (
                                    <div className="li-timer">
                                        {time < new Date(data.date_added) ? (
                                            <Timer
                                                date_added={data.date_added}
                                                date_closed={data.date_closed}
                                                withText={1}
                                                endText={'Time Left'}
                                                startText="Live Auction Starts In"
                                                theme={timerTheme}
                                            />
                                        ) : (
                                            <p className="text-center">
                                                Started In&nbsp;
                                                {moment(data.date_added)
                                                    .tz('America/New_York')
                                                    .format('MMM Do YYYY, h:mm a')}
                                            </p>
                                        )}
                                    </div>
                                ) : data.type != 'buynow' ? (
                                    <div className="li-timer">
                                        <Timer
                                            date_added={data.date_added}
                                            date_closed={data.date_closed}
                                            withText={1}
                                            endText={'Ends In'}
                                            startText="Starts in"
                                            theme=""
                                            icon={true}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>

                            {!noActionBtn && (
                                <>
                                    <div className="bids-buttons-wrap">
                                        <PrimaryButton
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    parseInt(
                                                        data?.event_enable ? data.event_enable : 0,
                                                    ) == 1
                                                        ? `eventAuction/${data.id}`
                                                        : details,
                                                )
                                            }
                                        >
                                            Bid Now
                                        </PrimaryButton>
                                        <Button
                                            onClick={(e) =>
                                                handleRedirectInternal(
                                                    history,
                                                    parseInt(
                                                        data?.event_enable ? data.event_enable : 0,
                                                    ) == 1
                                                        ? `eventAuction/${data.id}`
                                                        : global?.storeDetails?.theme == 19
                                                        ? details.replace('cat=0', 'cat=1')
                                                        : details + '?cat=1',
                                                )
                                            }
                                        >
                                            Categories
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </SkeletonTheme>
            ) : null}
        </>
    )
}

export default LotsGridAuction
